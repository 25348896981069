import React, { useEffect } from 'react'
import { Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerBody, Text, useBreakpointValue, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, Modal, Flex, ModalHeader, Center, VStack } from '@chakra-ui/react';
import UserWelcomeContent from './UserWelcomeContent';
import { analytics } from '../../services/analytics.service';

function UserWelcomeView(props: any) {
    const isDesktop = useBreakpointValue({ base: false, lg: true });
    const [isOpen, setIsOpen] = React.useState(false);

    useEffect(() => {
        if (props.isOpen) {
            const hasVisitedBefore = sessionStorage.getItem('hasVisited');
            if (!hasVisitedBefore) {
                setIsOpen(true);
            } 
        }
    }, [props.isOpen]);

    const handleClose:any = (type: any) => {
        analytics.sendUserExperinceSelection("Viewed")
        sessionStorage.setItem('hasVisited', 'true');
        //Google Analytics close event for both close and overlay
        if (type === "Skip") {
            analytics.sendUserExperinceSelection("Skipped")
        } else {
            analytics.sendUserExperinceSelection("Closed")
        }
        setIsOpen(false);
    };
 
    return isDesktop ? (
        <Modal isOpen={isOpen} onClose={handleClose} isCentered>
            <ModalOverlay />
            <ModalContent maxW={{ lg: "700px", xl: "750px" }}>
                <ModalHeader
                    backgroundImage="/WelcomeBg.svg"
                    backgroundRepeat="no-repeat"
                    backgroundSize="cover"
                    borderRadius="4px"
                    p="4"
                >
                    <Flex alignItems="center" justifyContent="space-between">
                        <Text fontSize="20px" fontWeight="500" pb={0.5} ml="2">
                            {props.data.title}
                        </Text>
                        <ModalCloseButton mr="2" my={2} outlineOffset={0} />
                    </Flex>


                </ModalHeader>
                <ModalBody backgroundColor="#FBF8F5" borderRadius="4px">
                    <Flex>
                        <Text fontSize="18px" fontWeight="400" mb="3" mt="1">
                            {props.data.sub_title}
                        </Text>
                    </Flex>
                    <UserWelcomeContent
                        isOpen={isOpen}
                        onClose={handleClose}
                        data={props.data}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>

    ) : (
        <Drawer isOpen={isOpen} placement="bottom" onClose={handleClose}>
            <DrawerOverlay>
                <DrawerContent borderRadius="10px">
                    <DrawerHeader
                        backgroundImage="/WelcomeBg.svg"
                        backgroundRepeat="no-repeat"
                        backgroundSize="cover"
                        borderRadius="4px"
                        p="4"
                    >
                        <Flex alignItems="center" justifyContent="space-between">
                            <Text fontSize="20px" pb={0.5} fontWeight="500">
                            {props.data.title}
                            </Text>
                            <ModalCloseButton mr="2" my={2} outlineOffset={0} />
                        </Flex>
                    </DrawerHeader>
                    <DrawerBody backgroundColor="#FBF8F5" px="4">
                        <Flex>
                            <Text fontSize="18px" fontWeight="400" mb="3" mt="1">
                            {props.data.sub_title}
                            </Text>
                        </Flex>
                        <UserWelcomeContent
                            isOpen={isOpen}
                            onClose={handleClose}
                            data={props.data}
                        />
                    </DrawerBody>
                </DrawerContent>
            </DrawerOverlay>
        </Drawer>
    );
}
export default UserWelcomeView;




