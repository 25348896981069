import { Box, Flex, Icon, Link, position, Stack, Text, useBreakpointValue } from "@chakra-ui/react";
import { FiMapPin, FiMessageCircle } from "react-icons/fi";
import React, { useEffect, useState } from "react";

import { BsFillChatFill } from "react-icons/bs";
import { IoCallOutline } from "react-icons/io5";
import { config } from "../../services/config.service";
import { prismicPageDataService } from "../../services/prismicPage.service";
import { PrismicRichText } from "@prismicio/react";
import Image from 'next/image';

const ContactSection = (props: any) => {
    const supportNumber = config.supportNumber;
    const [contactDetails, setContactDetails] = useState([] as any);
    let refinedData: any = [];

    const isDesktop = useBreakpointValue({ base: false, lg: true });

    useEffect(() => {
        const envCustomType: any = config.prismicContactCustomType?.toString();
        const envCustomUID: any = config.prismicContactUid?.toString();
        async function getContactData() {
            try {
                let contactData: any = await prismicPageDataService.getReqPrismicTypeById(`${envCustomType}`, `${envCustomUID}`);
                
                setContactDetails(contactData?.props?.pageRes);
            } catch (e) {
                console.log("contactData prismic error: ", e)
            }

        }
        getContactData();
    }, [setContactDetails]);

    return (contactDetails && contactDetails.length ?
        <Flex bg="#FBF8F5"
            pl={{ base: "15px", lg: "0" }}
            pb={{ base: "220px", lg: "191px" }}
            pr={{ base: "15px", lg: "0" }}
            pt={{
                sm: '30px',
                md: '30px',
                lg: '45px',
                xl: '100px',
                '2xl': '173px',
                base: "0",
            }}
            flexDir={{ base: "column", lg: "column" }}
            width={{ lg: "100%" }} align="center">
            <Flex flexDir={{ base: "column", lg: "row" }} width={{ lg: "100%" }} justifyContent={{ lg: 'center' }} gap={{ base: "5", lg: "24px" }} >
                {contactDetails.map((cData: any, key: any) =>
                (
                    <Box
                        width={{ lg: "286px" }}
                        p='24px'
                        key={key}
                        bg="#FFFFFF"
                    >
                        <Box
                            width="48px"
                            height="48px"
                            borderRadius="10px"
                            mb="64px"
                        >
                            {cData?.primary?.image?.url != "undefined" && <Flex>
                                <Image src={cData?.primary?.image?.url} alt={cData?.primary?.image?.alt != "undefined" && cData?.primary?.image?.alt != null ? cData?.primary?.image?.alt : cData?.primary?.title[0]?.text} width={48} height={48} />
                            </Flex>}
                        </Box>
                        <Flex
                            flexDir="column"
                            paddingTop={{ base: "40px", lg: "24px" }}
                        >
                            <Text fontSize={{ base: "18px", lg: "20px" }} fontWeight="500">
                                {cData?.primary?.title[0]?.text}
                            </Text>
                            <Text mt="8px" fontSize="16px" fontWeight="400" color="#E47066">
                                {cData?.primary?.sub_title[0]?.text}
                            </Text>
                            <Text mt="20px" fontSize="16px" fontWeight="400" color="#E47066">
                                <PrismicRichText field={cData?.primary?.description}></PrismicRichText>
                            </Text>
                        </Flex>
                    </Box>
                )
                )}

            </Flex>
            <Box backgroundImage={isDesktop ? "/images/lastHomepageImage.svg" : "/mobileFooter.png"}
                style={{
                    position: "absolute",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "fill",
                }}
                flexDir="row"
                width="100%"
                height="212px"
                backgroundPosition={"bottom"}
                pt={isDesktop ? "600px" : "1590px"}
                opacity={isDesktop ? "1" : "0.6"}>
            </Box>
        </Flex > : <Box></Box>
    )
}
export default ContactSection;