import { Box, Center, Flex, Grid, HStack, Stack, Text, VStack } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";

import StaticUtils from "../../services/static-utils.service";
import WhyRentitySection from './WhyRentitySection';
import { config } from "../../services/config.service";
import moment from "moment"
import { prismicPageDataService } from '../../services/prismicPage.service';
import { useRouter } from 'next/router'

const WhyRentity = (props: any) => {
    const [whyRentity, setWhyRentity] = useState([]);
    const [whyRentityTitle, setWhyRentityTitle] = useState();
    const [whyRentityDesc, setWhyRentityDesc] = useState();

    useEffect(() => {
        let refinedWRData: any = [];
        const envPageUID: any = config.prismicWhyRentityPage?.toString();
        const envCustomType: any = config.prismicWhyRentityCustomType?.toString();
        const envRFUID: any = config.prismicRentityFeaturesUid?.toString();
        async function getRentityFeatures() {
            try {
                let rentityFeatures: any = await prismicPageDataService.getReqPrismicTypeById(`${envCustomType}`, `${envRFUID}`);

                rentityFeatures.props.pageRes.map((val: any, key: any) => {

                    refinedWRData.push({
                        "text": `${val?.primary?.title[0]?.text}`,
                        "color": `${val?.primary?.color}`,
                        "bgImage": `${val?.primary?.background_image?.url}`
                    });

                });
                setWhyRentity(refinedWRData);
                return refinedWRData;
            } catch (e) {
                console.log("whyRentity rentityFeatures prismic fetch error: ", e)
            }
        }
        async function getWhyRentityDetails() {
            try {
                let whyRentityData = await prismicPageDataService.getReqPrismicPageData(`${envPageUID}`);
                const pageData = whyRentityData?.props?.pageRes;
                setWhyRentityTitle(whyRentityData?.props?.pageRes.title[0]?.text);
                setWhyRentityDesc(whyRentityData?.props?.pageRes.description[0]?.text);
            } catch (e) {
                console.log("whyRentityData prisic fetch error: ", e)
            }
        }
        getRentityFeatures();
        getWhyRentityDetails();
    }, [setWhyRentity]);

    return (whyRentity && whyRentity.length ?
        <Box bg="#FBF8F5">
            <Box ml={{ base: "15px", lg: "0" }} mr={{ base: "15px", lg: "0" }} pb="40px" h={{ lg: '100%', base: '100%' }} background='linear-gradient(180deg, #4B6189 0%, #1F2D47 100%)'>
                <Center>
                    <VStack mt={{ lg: '51px', base: '15px' }}>
                        <Text fontSize='24px' fontWeight='700' mb={{ lg: '42px', base: '20px' }} color='#FBF8F5' lineHeight="24px" fontFamily={StaticUtils.titleFontName}>{whyRentityTitle}</Text>
                        <Flex style={{ 'margin': '0px' }} direction={{ lg: 'row', base: 'column' }} gap='9px' maxW='1020px' flexWrap='wrap'>
                            {whyRentity.map((item: any, key: any) => {
                                return (
                                    <WhyRentitySection key={key} text={item.text} color={item.color} bgImage={item.bgImage} />
                                )
                            })
                            }
                        </Flex>
                        <Text style={{ margin: '0px' }} textAlign={{ lg: 'center', base: 'justify' }} fontFamily={StaticUtils.titleFontName} fontSize={{ base: "14px", lg: '16px' }} lineHeight={{ lg: '36px', base: '30px' }} fontWeight='400' pt={{ lg: '34px', base: '20px' }} maxW={{ lg: '783px', base: '331px' }} color='#FBF8F5'>
                            {whyRentityDesc}
                        </Text>
                    </VStack>
                </Center>
            </Box>
        </Box> : <Box></Box>
    )
}
export default WhyRentity;