import { Box, Button, Flex, HStack, Icon, Image, Stack, Text, VStack, useBreakpointValue } from "@chakra-ui/react"
import { MdOutlineArrowRight, MdPlayArrow } from "react-icons/md";
import React, { useEffect, useState } from "react";

import StaticUtils from "../../services/static-utils.service";
import SubSection from './SubSection';
import { config } from "../../services/config.service";
import { icons } from "react-icons/lib";
import { prismicPageDataService } from "../../services/prismicPage.service";
import router from "next/router";

const Section = (props: any) => {
    const isDesktop = useBreakpointValue({ base: false, lg: true });

    const [subsections, setSubSections] = useState([]);

    useEffect(() => {
        let refinedHIWData: any = [];
        const envCustomType: any = config.prismicHowItWorksCustomType?.toString();
        const envHIWUID: any = config.prismicHowItWorksUid?.toString();
        async function getHowItWorksData() {
            try {
                let howItWorksData: any = await prismicPageDataService.getReqPrismicTypeById(`${envCustomType}`, `${envHIWUID}`);

                howItWorksData.props.pageRes.map((val: any, key: any) => {

                    refinedHIWData.push({
                        "mainImage": `${val?.primary?.main_image?.url}`,
                        "mainImageAlt": `${val?.primary?.main_image?.alt}`,
                        "numberImage": `${val?.primary?.number_image?.url}`,
                        "numberImageWidth": `${val?.primary?.number_image?.dimensions?.width}`,
                        "numberImageHeight": `${val?.primary?.number_image?.dimensions?.height}`,
                        "numberImageAlt": `${val?.primary?.number_image?.alt}`,
                        "titleText": `${val?.primary?.title[0]?.text}`,
                        "description": `${val?.primary?.description[0]?.text}`
                    });

                });
                setSubSections(refinedHIWData);
                return refinedHIWData;
            } catch (e) {
                console.log("howItWorksData prismic fetch error: ", e)
            }
        }
        getHowItWorksData();
    }, []);

    return (subsections && subsections.length ?
        <Box bg={{ base: "#FBF8F5", lg: "#FFFFFF" }}>
            <Box bg="#FFFFFF" maxWidth="100%" color="white" ml={{ base: "15px", lg: "0" }} mr={{ base: "15px", lg: "0" }}>
                <Text fontFamily={StaticUtils.titleFontName} color='#1F2D47' textAlign={{ base: 'center', lg: 'initial' }} fontWeight="700" fontSize={{ base: "20px", lg: '24px' }} pt={{ lg: '83px', base: '7' }} pl={{ lg: '110px', "2xl": "120px" }}>HOW IT WORKS</Text>
                <Flex flexWrap='wrap' direction={{ base: "column", lg: "row" }} alignItems="center" justifyContent="center">
                    {subsections.map((item: any, key: any) => {
                        return (
                            <SubSection mainImage={item.mainImage}
                                key={key}
                                numberImage={item.numberImage}
                                numberImageWidth={item.numberImageWidth}
                                numberImageHeight={item.numberImageHeight}
                                titleText={item.titleText}
                                description={item.description}
                                mainImageAlt={item.mainImageAlt}
                                numberImageAlt={item.numberImageAlt}
                            />
                        )
                    })
                    }
                </Flex>
                <Stack alignItems="center" justifyContent="center" mt={{ base: "5", lg: "40px" }}>
                    <Button variant='outline' w="238px" h="41px" fontWeight="700" fontFamily={StaticUtils.titleFontName} fontSize="16px" mb={{ base: "10" }}
                        onClick={() => router.push("/search?search=;category=")}
                    >{isDesktop ? "Start renting" : "Browse all products"}</Button>
                </Stack>
            </Box>


        </Box> : <Box></Box>
    )
}
export default Section;