import {
  Box,
  Flex,
  useBreakpointValue,
} from "@chakra-ui/react";
import { QueryClient, useQuery } from "react-query";
import React, { useState } from "react";

import BrandCarousel from "../components/Carousel/BrandCarousel";
import ContactSection from "../components/contactSection/ContactSection";
import ContentPages from "../components/ContentPages/ContentPages";
import CustomerReviews from "../components/CustomerReviews/CustomerReviews";
import Head from 'next/head';
import HeroBannerCarousel from "../components/Carousel/HeroBannerCarousel";
import HomeNewCollectionCarousel from "../components/Carousel/HomeNewCollectionCarousel";
import MobCategory from "../components/Header/MobCategory";
import { NextPage } from "next";
import ProductCarousel from "../components/Carousel/ProductCarousel";
import Section from "../components/HowItWorks/Section";
import WhyRentity from "../components/WhyRentity/WhyRentity";
import { cartservice } from "../services/graphql/cart.service";
import { config } from "../services/config.service";
import { dehydrate } from "react-query/hydration";
import { homepageservice } from "../services/graphql/homepage.service";
import { prismicPageDataService } from "../services/prismicPage.service";
import { productSearchService } from "../services/graphql/product-search.service";
import { analytics } from "../services/analytics.service";
import UserWelcomeView from "../components/UserWelcomePage/UserWelcomeView";

const queryClient = new QueryClient();

const envHerotype: any = config.prismicHeroBanner?.toString();
const envHeroUID: any = config.prismicHomepageHeroBannerUid?.toString();
const STALE_TIME = Number(config.serverSideStaleTime || '6000000');

const envHeroStaticBannerType: any = config.prismicHeroCarouselStaticBanner?.toString();
const envHeroStaticBannerUID: any = config.prismicHomepageStaticBannerUid?.toString();

const envArtInteriorsType: any = config.prismicHomepageCollectionSectionType?.toString();
const envArtInteriorsUID: any = config.prismicHomepageCollectionSectionUid?.toString();

const envPageUID: any = config.prismicCustomerReviews?.toString();
const envReviewsUID: any = config.prismicCustomerReviewsUid?.toString();

const envBrowseByBrandsUID: any = config.prismicHomepageBrandsBannerUid?.toString();
const envHpBrandSection: any = config.prismicHomepageBrowseByBrands?.toString();

const pageId: any = config.prismicAboutRentity?.toString();

const envHpCaraouselType: any = config.prismicHomepageCarouselType?.toString();
const envCarouselTypeUID: any = config.prismicHomepageCarouselTypeSectionUid?.toString();

const envCollectionSliceType: any = config.prismicCollectionCarouselSliceType?.toString();

const envFooterType: any = config.prismicFooter?.toString();
const envFooterUID: any = config.prismicFooterUid?.toString();

let allCategories: any;
let footerData: any;
let footerNoteText: any;
let carouselTypes: any;
// let catProducts: any = {};

setInterval(() => {
  try {
    console.log('>>>>>>>>>>>>>>>>> TIME TO CLEAR CACHE <<<<<<<<<<<<<<<<<<');
    allCategories = undefined;
    footerData = undefined ;
    carouselTypes = undefined;
    // catProducts = {};
    queryClient.clear();
  } catch (e) {
    console.log('setInterval: ', e);
  }
}, STALE_TIME);

const Home: NextPage = (props: any) => {

  const [pageTitle, setPageTitle] = useState("");
  const [pageSynopsis, setPageSynopsis] = useState("");
  const [pagePromoImgUrl, setPagePromoImgUrl] = useState("");
  const [pagePromoImgAltText, setPagePromoImgAltText] = useState("");
  const [pagePromoImgWidth, setPagePromoImgWidth] = useState("");
  const [pagePromoImgHeight, setPagePromoImgHeight] = useState("");
  const [pageDescription, setPageDesc] = useState("");
  const [pageLink, setPageLink] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const isUserWelcomeEnabled = config.isUserWelcomeEnabled;   

  const [cartData, setCartData] = React.useState(null as any)

  const { data: prismicData, isLoading: prismicDataLoading } = useQuery(["AboutRentity", { pageId }], () => prismicPageDataService.getReqPrismicPageData(`${pageId}`), { staleTime: STALE_TIME });

  const pageData = prismicData?.props?.pageRes;

  const { data: testimonials, isLoading: testimonialsLoading } = useQuery(["Testimonials", { envPageUID, envReviewsUID }], () => prismicPageDataService.getReqPrismicTypeById(`${envPageUID}`, `${envReviewsUID}`), { staleTime: STALE_TIME });
  const reviewData = testimonials?.props?.pageRes;

  const { data: browseByBrand, isLoading: browseByBrandLoading } = useQuery(["BrowseByBrands", { envHpBrandSection, envBrowseByBrandsUID }], () => prismicPageDataService.getReqPrismicTypeById(`${envHpBrandSection}`, `${envBrowseByBrandsUID}`), { staleTime: STALE_TIME });
  const browseBrandsData = browseByBrand?.props?.pageRes;

  const carouselTypes = props?.carouselTypes?.props?.pageRes;

  const footerNote = props?.footerNoteText;

  // const { data: artsAndInteriors, isLoading: artsAndInteriorsLoading } = useQuery(["ArtsAndInteriors", { envArtInteriorsType, envArtInteriorsUID }], () => prismicPageDataService.getReqPrismicCustomTypeData(`${envArtInteriorsType}`, `${envArtInteriorsUID}`), { staleTime: STALE_TIME });
  // const artsInteriorsData = artsAndInteriors?.props?.pageRes;

  const { data: heroCarouselData, isLoading } = useQuery(["HeroCarousel", { envHerotype, envHeroUID }], () => prismicPageDataService.getReqPrismicTypeById(`${envHerotype}`, `${envHeroUID}`), { staleTime: STALE_TIME });
  const { data: heroStaticBanner, isLoading: loadingHeroStaticData } = useQuery(["HeroStaticBanner", { envHeroStaticBannerType, envHeroStaticBannerUID }], () => prismicPageDataService.getReqPrismicTypeById(`${envHeroStaticBannerType}`, `${envHeroStaticBannerUID}`), { staleTime: STALE_TIME });

  React.useEffect(() => {
    if (pageData?.title[0]?.text != "") {
      setPageTitle(pageData?.title[0]?.text);
      setPageSynopsis(pageData?.synopsis[0]?.text);
      setPagePromoImgUrl(pageData?.promoimg?.url);
      setPageDesc(pageData?.description[0]?.text);
      setPagePromoImgAltText(pageData?.promoimg?.alt);
      setPagePromoImgWidth(pageData?.promoimg?.dimensions?.width);
      setPagePromoImgHeight(pageData?.promoimg?.dimensions?.height);
      setPageLink(pageData?.more_link?.url);
    }

    async function getCartData() {
      const c = await cartservice.getCartProducts();
      setCartData(c);
    }
    getCartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
    md: false,
    sm: false,
  });

  let logSrc = '';
  if (typeof (window) !== 'undefined') {
    logSrc = location.origin + '/images/og-image-logo.jpg';
  }

  return (
    <>
      <Head>
        <title>Rentity | Home</title>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Rentity - World's Renting Platform",
              "url": "http://rentity.in/",
              "logo": "https://rentity.in/images/rentity_logo_100px.png"
            })
          }}
        />
        <meta name="description" content={footerNote || 'World’s Renting Platform'}></meta>
        <meta name="robots" content="index, follow"></meta>
        <meta property="og:image" content="https://www.rentity.in/images/og-image-logo.jpg" />
      </Head>
      <Box className="main-body" bg={{ base: "#FBF8F5", lg: 'white' }}>
        {!isDesktop && (
          <Box
            style={{ 'margin': '0px 10px' }}
            bg="#FBF8F5"
            mt={5}
            alignItems="center"
            justifyContent="center"
          >
            <MobCategory {...props} />
          </Box>
        )}

        <Box overflowX={"hidden"}>

          <Box className="hearo-carousel" pr={isDesktop ? "64px" : "0"} marginTop={{ base: '10px', lg: '17px' }}
          // borderTop={{ base: '0px solid #E5E5E5', md: '0px solid #E5E5E5', lg: '7px solid #E5E5E5' }}
          >

            <HeroBannerCarousel heroCarousel={heroCarouselData} heroStaticBanner={heroStaticBanner} />
          </Box>

          <Box className="home-carousels"
            pl={isDesktop ? "24px" : "10px"}
            pr={isDesktop ? '24px' : '0px'}
          >
            {browseBrandsData && <Flex
              flexDirection="column"
              mt={{ base: "20px" }}
              mb={{ base: "10px" }}
            >
              <BrandCarousel data={browseBrandsData} />
            </Flex>}
          </Box>

          <Box className="home-carousels"
          >
            {/*  {artsInteriorsData && <Flex
              flexDirection="column"
              mt="10"
            >
              <HomeNewCollectionCarousel data={artsInteriorsData} />
            </Flex>} */}
            <Flex
              flexDirection="column"
              mt={{ base: '0px', lg: "10" }}
            >

              {props?.carouselCategories &&
                props?.carouselCategories?.map((cat: any, idx: number) => {
                  if (cat?.products?.totalItems > 0) {
                    return <ProductCarousel
                      cartProductIds={cartData ? cartData.lines : []}
                      data={cat}
                      title={carouselTypes[idx]?.primary?.title?.[0]?.text}
                      carousalType={carouselTypes[idx]?.primary?.carousel_type}
                      backgroundColour={carouselTypes[idx]?.primary?.background_colour}
                      products={cat.products} key={idx} />;
                  }
                  if (cat?.collection != "" && cat?.collection != null) {
                    return cat?.items && <HomeNewCollectionCarousel title={cat?.title} data={cat?.items} bgColor={cat?.bgColor} key={idx} />
                  }

                })}
            </Flex>
          </Box>
          <Section />
        </Box>
        {pageTitle && pageSynopsis && <ContentPages
          pageTitle={pageTitle}
          pageSynopsis={pageSynopsis}
          pagePromoImgUrl={pagePromoImgUrl}
          pagePromoImgAltText={pagePromoImgAltText}
          pagePromoImgWidth={pagePromoImgWidth}
          pagePromoImgHeight={pagePromoImgHeight}
          pageDescription={pageDescription}
          pageLink={pageLink}
        />}
        <WhyRentity />
        {reviewData && <CustomerReviews reviewData={reviewData} />}
        <ContactSection />
      </Box>
      {
        isUserWelcomeEnabled === "true" && (
          <UserWelcomeView isOpen={isDrawerOpen} data={props.userWelcomeData} />
        )
      }

    </>
  );
};

export async function getServerSideProps({ req, res }: any) {
  const tn = 'Homepage-timer-' + Math.random().toString();
  console.time(tn);

  var {
    allCategories,
    featuredCategories,
    bannerCategories,
    taglineCategories,
    carouselCategories,
    carouselTypes,
    footerData,
    footerNoteText,
    footerCategories,
    userWelcomeData,
  }: {
    allCategories: any;
    featuredCategories: any[];
    bannerCategories: any[];
    taglineCategories: any[];
    carouselCategories: any[];
    carouselTypes: any;
    footerData: any;
    footerNoteText: any;
    footerCategories: any[];
    userWelcomeData: any[];
  } = await getHomePageCache();

  console.timeEnd(tn);

  return {
    props: {
      allCategories,
      featuredCategories,
      bannerCategories,
      taglineCategories,
      carouselCategories,
      dehydratedState: dehydrate(queryClient),
      carouselTypes,
      footerData,
      footerNoteText,
      footerCategories,
      userWelcomeData,
    },
  };
}

export default Home;
async function getHomePageCache() {

  console.time('footerData');
  // let footerData: any;
  // let footerNoteText: any;
  if (!footerData) {
    try {
      footerData = await prismicPageDataService.getReqPrismicCustomTypeData(`${envFooterType}`, `${envFooterUID}`);
      footerNoteText = footerData?.props?.pageRes?.description?.map((a: any) => a.text).join('. ');
    } catch (e) {
      footerData = null;
      footerNoteText = null;
      console.log("getFooterData error: ", e)
    }
  }
  console.timeEnd('footerData');

  // let footerNoteText: any;
  // if (!footerNoteText) {
  //   try {
  //     footerNoteText = footerData?.props?.pageRes?.description?.map((a: any) => a.text).join('. ');
  //   } catch (e) {
  //     footerNoteText = null;
  //     console.log('error: ', e);
  //   }
  // }

  try {
    console.time('AboutRentity');
    queryClient.prefetchQuery(["AboutRentity", { pageId }], () => prismicPageDataService.getReqPrismicPageData(`${pageId}`), { staleTime: STALE_TIME });
    console.timeEnd('AboutRentity');
  } catch (e) {
    console.log("AboutRentity prefetch error: ", e)
  }

  try {
    console.time('Testimonials');
    queryClient.prefetchQuery(["Testimonials", { envPageUID, envReviewsUID }], () => prismicPageDataService.getReqPrismicTypeById(`${envPageUID}`, `${envReviewsUID}`), { staleTime: STALE_TIME });
    console.timeEnd('Testimonials');
  } catch (e) {
    console.log("Testimonials prefetch error: ", e)
  }

  try {
    console.time('HeroCarousel');
    queryClient.prefetchQuery(["HeroCarousel", { envHerotype, envHeroUID }], () => prismicPageDataService.getReqPrismicTypeById(`${envHerotype}`, `${envHeroUID}`), { staleTime: STALE_TIME });
    console.timeEnd('HeroCarousel');
  } catch (e) {
    console.log("HeroCarousel prefetch error: ", e)
  }

  try {
    console.time('HeroStaticBanner');
    queryClient.prefetchQuery(["HeroStaticBanner", { envHeroStaticBannerType, envHeroStaticBannerUID }], () => prismicPageDataService.getReqPrismicTypeById(`${envHeroStaticBannerType}`, `${envHeroStaticBannerUID}`), { staleTime: STALE_TIME });
    console.timeEnd('HeroStaticBanner');
  } catch (e) {
    console.log("HeroStaticBanner prefetch error: ", e)
  }

  try {
    console.time('BrowseByBrands');
    queryClient.prefetchQuery(["BrowseByBrands", { envHpBrandSection, envBrowseByBrandsUID }], () => prismicPageDataService.getReqPrismicTypeById(`${envHpBrandSection}`, `${envBrowseByBrandsUID}`), { staleTime: STALE_TIME });
    console.timeEnd('BrowseByBrands');
  } catch (e) {
    console.log("BrowseByBrands prefetch error: ", e)
  }

  console.time('carouselTypes');
  // let carouselTypes: any;
  if (!carouselTypes) {
    try {
      carouselTypes = await prismicPageDataService.getReqPrismicTypeById(`${envHpCaraouselType}`, `${envCarouselTypeUID}`);
    } catch (e) {
      carouselTypes = null;
      console.log("error fetching carousalTypes: ", e)
    }
  }
  console.timeEnd('carouselTypes');
  const caraousalTypesList: any = carouselTypes?.props?.pageRes;

  let userWelcomeData: any = {};
  userWelcomeData = await prismicPageDataService.getReqPrismicCustomTypeData("home_page_section_user_welcome_experience", "home_page_section_user_welcome_experience"   )
  userWelcomeData = userWelcomeData?.props?.pageRes;
  // let allCategories: any;
  if (!allCategories) {
    try {
      allCategories = await homepageservice.getCategories();
    } catch (e) {
      console.log("error fetching allCategories:  ", e)
    }
  }
  let featuredCategories: Array<any> = [];
  let bannerCategories: Array<any> = [];
  let taglineCategories: Array<any> = [];
  let carouselCategories: Array<any> = [];
  let footerCategories: Array<any> = [];
  console.timeEnd('getCategories');

  console.time('forLoop');
  let catProducts: any = {};
  for (let i = 0; i < allCategories?.length; i++) {
    const cat = allCategories[i];

    if (cat.customFields.featuredCollection) {
      featuredCategories.push(cat);
    }

    for (let j = 0; j < caraousalTypesList?.length; j++) {
      const catSlug = caraousalTypesList[j].primary.slug;
      const collectionCarousels = caraousalTypesList[j].slice_type;
      const cCTitle = caraousalTypesList[j].primary?.title[0]?.text;
      const bgColor = caraousalTypesList[j].primary?.bgColor;

      if ((catSlug != "undefined" && catSlug != null)) {
        if ((catSlug).toLowerCase() === cat.slug) {
          let products: any;

          if (catProducts[cat.id]) {
            products = catProducts[cat.id];
          } else {
            try {
              products = await productSearchService.searchProduct(0, 10, "", cat.id, []);
              catProducts[cat.id] = products;
            } catch (e) {
              console.log("error fetching Products: ", e)
            }
          }

          // TODO : the getCollections GQL should return the product details for better performance.
          carouselCategories[j] = ({ ...cat, products });
        }
      }
      if (collectionCarousels == envCollectionSliceType) {
        const colItemsLength = caraousalTypesList[j].items.length;
        if (colItemsLength > 0) {
          const colItems = caraousalTypesList[j].items;
          carouselCategories[j] = ({ collection: envCollectionSliceType, "title": cCTitle, "items": colItems, "bgColor": bgColor });
        }
      }
    }


    footerCategories = (allCategories.filter((c: any) => {
      return !c.customFields.featuredCollection && c.featuredAsset;
    }));
  };
  console.timeEnd('forLoop');



  return { allCategories, featuredCategories, bannerCategories, taglineCategories, carouselCategories, carouselTypes, footerData, footerNoteText, footerCategories, userWelcomeData };
}

