import { Box, Flex, Text, VStack, useBreakpointValue } from "@chakra-ui/react";
import React, { useRef, useState } from "react";

import Slider from "react-slick";
import StaticUtils from "../../services/static-utils.service";
import Image from 'next/image';

const HappyCustomersCarousel = (props: any) => {
    const isDesktop = useBreakpointValue({ base: false, lg: true });

    const { reviews } = props;

    function NextArrow(props: any) {
        const { style, onClick } = props;

        // function next() {
        //     if (customeSlider) {
        //         customeSlider.current.slickNext();
        //     }
        // }

        return (
            <div
                onClick={onClick}
                style={{
                    ...style, top: "0",
                    justifyContent: "flex-end",
                    flexDirection: "row",
                    paddingRight: isDesktop ? "80px" : "10px",
                    cursor: "pointer",
                    position: "absolute",
                    minWidth: "100px",
                    height: "100%",
                    right: "0",
                    display: "flex",
                    alignItems: "center",
                    zIndex: "1"
                }
                }>
                <Image src="/next.png" alt="Next" width={34} height={34} />
            </div >
        );
    }

    function PrevArrow(props: any) {
        const { style, onClick } = props;

        // function prev() {
        //     if (customeSlider) {
        //         customeSlider.current.slickPrev();
        //     }
        // }
        return (
            <div
                onClick={onClick}
                style={{
                    ...style, justifyContent: isDesktop ? "flex-end" : 'flex-start',
                    flexDirection: "row",
                    paddingLeft: isDesktop ? "80px" : "10px",
                    cursor: "pointer",
                    position: "absolute",
                    minWidth: "100px",
                    height: "100%",
                    left: "0",
                    display: "flex",
                    alignItems: "center",
                    zIndex: "1"
                }}>
                <Image src="/prev.png" alt="Previous" width={34} height={34} />
            </div>
        );
    }

    const [reviewData, setReviewsData] = useState([]);

    React.useEffect(() => {
        if (reviews) {
            setReviewsData(reviews);
        }
    }, [reviews]);

    const showDots = isDesktop ? true : false;
    var settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        arrows: true,
        accessibility: true,
        dots: showDots,
        autoplaySpeed: 6000,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    arrows: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    arrows: true,
                },
            },
        ],
    };
    return (
        <Box bg="#FBF8F5">
            <Box bg="#FFFFFF" mb={{ base: "20px" }} className="reviewBox" ml={{ base: "15px", lg: "0" }} mr={{ base: "15px", lg: "0" }} mt={{ base: "20px" }}>
                <Slider {...settings} >
                    {reviewData.map((review: any, key: any) =>
                    (
                        <Box key={key} >
                            <Flex pl={{ base: "0px", "lg": "319px" }} pt={{ lg: "84px" }} pb={{ base: "8px", lg: "84px" }}  justifyContent={{sm :"center", md:"center"}}>
                                {isDesktop && <VStack display={"flex"}>
                                    <Image src={`${review.profilePic}`} alt={review.name} width={`${review.width}`} height={`${review.height}`} />
                                </VStack>}
                                <VStack display={"flex"} justifyContent={"flex-start"} alignItems={!isDesktop ? "center" : "flex-start"} pl={{ base: "0px", lg: "140px" }}>
                                    <VStack spacing="0">
                                        <VStack ml={0} px={0} pt={3} pb={3} alignSelf="baseline">
                                            <Image src="/quotes.png" width={24} height={18} alt="Quotes" />
                                        </VStack>
                                        <VStack pt={0} pb={26}>
                                            <Text color="#1F2D47" fontFamily={StaticUtils.titleFontName} fontWeight={700} fontSize={{ base: "20px", lg: "24px" }} lineHeight="24px" letterSpacing={"0.11em"}>HAPPY CUSTOMERS</Text>
                                        </VStack>
                                    </VStack>
                                    {!isDesktop && <VStack display={"flex"}>
                                        <Image src={`${review.profilePic}`} alt={review.name} width={215} height={242} />
                                    </VStack>}
                                    <VStack pl={isDesktop ? 0 : 26} pr={isDesktop ? 148 : 26} pt={isDesktop ? 0 : 5} pb={isDesktop ? 49 : 5} textAlign={"justify"} maxWidth={559} textOverflow={"ellipsis"} flexWrap={"wrap"} overflow={"hidden"}>
                                        <Text display={"flex"} lineHeight="27px" justifyContent={"flex-start"} alignItems={"flex-start"} fontWeight={400} fontFamily={StaticUtils.titleFontName} fontSize={16} letterSpacing={"0.02em"} textTransform={"capitalize"} color="#1F2D47">
                                            {review.review != "undefined" ? review.review : ""}
                                        </Text>
                                    </VStack>
                                    <VStack fontFamily={StaticUtils.titleFontName} fontWeight={400} fontSize={16} lineHeight="27px" letterSpacing={"0.02em"} textTransform={"capitalize"} color="#1F2D47">
                                        <Text>{review.name != "undefined" ? review.name : ""}</Text>
                                    </VStack>
                                    <VStack fontFamily={StaticUtils.titleFontName} fontWeight={400} fontSize={16} lineHeight="27px" letterSpacing={"0.02em"} textTransform={"capitalize"} color="#1F2D47">
                                        <Text>{review.designation != "undefined" ? review.designation : ""}</Text>
                                    </VStack>
                                    <VStack fontFamily={StaticUtils.titleFontName} fontWeight={400} fontSize={16} lineHeight="27px" letterSpacing={"0.02em"} textTransform={"capitalize"} color="#1F2D47">
                                        <Text>{review.location != "undefined" ? review.location : ""}</Text>
                                    </VStack>
                                </VStack>
                            </Flex>
                        </Box>
                    )
                    )}
                </Slider>
            </Box>
        </Box>
    );
};

export default HappyCustomersCarousel;
