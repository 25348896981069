import { Box, Divider, Flex, HStack, Image, Link, Popover, PopoverTrigger, Stack, Text, VStack, useColorModeValue } from "@chakra-ui/react";

import React from "react";
import Router from "next/router";
import StaticUtils from "../../services/static-utils.service";
import { config } from "../../services/config.service";
import { prismicPageDataService } from "../../services/prismicPage.service";

function MobCategory(props: any) {
  const [categories, setCategories] = React.useState([] as any[]);
  const [featuredCollection, setFaturedCollection] = React.useState<any>();

  function handleNav(slug: any): void {
    Router.push("/category/" + slug.toLowerCase());
  }
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const envCustomType: any = config.prismicFeaturedCollection?.toString();
  const envFeaturedUID: any = config.prismicFeaturedBrandsUid?.toString();
  let refinedFeaturesData: any = [];

  // async function getAllFeaturedCols() {
  //   if (featuredCollection && featuredCollection.length) {
  //     return;
  //   }

  //   let featuredData: any = await prismicPageDataService.getReqPrismicTypeById(`${envCustomType}`, `${envFeaturedUID}`);
  //   featuredData.props.pageRes?.map((val: any, key: any) => {

  //     refinedFeaturesData.push({
  //       "title": `${val?.primary?.title[0]?.text}`,
  //       "logo": `${val?.primary?.featured_logo?.url}`,
  //       "logoHeight": `${val?.primary?.featured_logo?.dimensions?.width}`,
  //       "logoWidth": `${val?.primary?.featured_logo?.dimensions?.height}`,
  //       "url": `${val?.primary?.url?.url}`,
  //       "tooltip": `${val?.primary?.tooltip[0]?.text}`
  //     });

  //   });

  //   setFaturedCollection(refinedFeaturesData);

  //   return refinedFeaturesData;
  // }

  React.useEffect(() => {
    async function getAllCategories() {
      if (categories && categories.length) {
        return;
      }

      try {

        let allCat = props.allCategories;
        let categories: {
          id: any;
          name: any;
          children: never[];
          slug: any;
          parent: any;
          featuredAsset: any;
        }[] = [];
        allCat.map((cat: any) => {
          let parentNode: any = {
            id: cat.id,
            name: cat.name,
            children: [] as never[],
            slug: cat.slug,
            parent: cat.parent,
            customFields: cat.customFields,
            featuredAsset: cat.featuredAsset,
          };
          allCat.map((cat2: any) => {
            if (cat.id === cat2.parent.id) {
              let child: any = {
                id: cat2.id,
                name: cat2.name,
                slug: cat2.slug,
              };
              parentNode.children.push(child);
            }
          });
          categories.push(parentNode);
        });

        setCategories(categories.filter((c: any) => {
          return !c.customFields.featuredCollection && c.featuredAsset
        }))

        // setFaturedCollection(categories.filter((c: any) => {
        //   return c.customFields.showInBanner && c.customFields.featuredCollection
        // }));


        // if (!featuredCollection) {//uncomment this to show featured collection from prismic
        //   getAllFeaturedCols();
        // }


      } catch (e) {
      }
    }
    getAllCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.allCategories]);

  return (
    <Stack width="100%" pt="20px">
      <HStack width="100%" overflow={"scroll"} className="hstackcol"
        alignItems='flex-start'
        columnGap='30px'>
        {categories && categories.length ?
          <>
            <Stack>
              <Link href="/search?search=;category=">
                <Box
                  flex="1"
                  textAlign="center"
                  fontSize="15px"
                  fontWeight="500"
                  width="100%"
                  whiteSpace='nowrap'
                  // onClick={() => Router.push("/search?search=;category=")}
                  cursor={"pointer"}
                  fontFamily={StaticUtils.titleFontName}
                >
                  {"All Products"}
                </Box>
              </Link>
            </Stack>

            {categories.map((products: any, id: any) => {

              return (
                <Stack textAlign="center" style={{ 'margin': '0' }} justifyContent="space-between" key={id}>

                  <Box

                    flex="1"
                    textAlign="center"
                    fontSize="15px"
                    fontWeight="500"
                    whiteSpace='nowrap'
                    cursor={"pointer"}
                    fontFamily={StaticUtils.titleFontName}
                  >
                    <Link href={"/category/" + products.slug.toLowerCase()}>
                      {products.name}
                    </Link>
                  </Box>
                </Stack>
              );

            })}
          </>
          : <Box></Box>
        }

        {/* <Divider
          orientation="vertical"
          color="gray.800"
          opacity="1"
          borderLeftWidth="2px"
          height="75px"
          ml="25px"
          mr="35px"
        />
        <VStack gap={{ base: '20px', lg: '20px' }} alignItems='flex-start'>
          <Text fontFamily={StaticUtils.titleFontName} fontSize='14px' fontWeight='500' opacity={'.6'} color='#1F2D47' lineHeight='20px'>Shop by brand</Text>
          <HStack gap={{ base: '20px', lg: '20px', xl: '20px', '2xl': '41px' }} style={{ 'margin': '0px' }}>
            {featuredCollection && featuredCollection.map((navItem: any, id: any) => {
              return (


                <Box key={navItem.title}>
                  <Flex
                    direction="column"
                    transition='all .3s ease 0s'
                    _hover={{ opacity: "0.8" }}
                  // mt={{ lg: "20px", xl: "5px" }}
                  >
                    <Link href={navItem?.url}>
                      <Box
                        flex="1"
                        textAlign="center"
                        fontSize="14px"
                        fontWeight="500"
                        width="80px"
                      >
                        <Image
                          src={navItem?.logo}
                          alt={(navItem?.tooltip && navItem?.tooltip != "undefined") ? navItem.tooltip : navItem?.title}
                          title={(navItem?.tooltip && navItem?.tooltip != "undefined") ? navItem.tooltip : navItem?.title}
                          minWidth={'auto'}
                          maxWidth='100%'
                          height="34px"
                        />

                      </Box>
                    </Link>
                  </Flex>
                </Box>

              );
            })}
          </HStack>
        </VStack> */}
      </HStack>
    </Stack>
  );
}

export default MobCategory;
