import { Box, Center, Stack, Text } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";

import StaticUtils from "../../services/static-utils.service";
import moment from "moment"
import { useRouter } from 'next/router'

type ListProps = {
    text: string;
    color: string;
    bgImage: string;

}

const WhyRentitySection = (props: ListProps) => {
    const { text, color, bgImage } = props
    return (
        <>
            <Box display="flex" alignItems='center' mb={{ base: '0px', lg: '21px' }} justifyContent='center' bgColor={color} backgroundImage={`url(${bgImage})`} backgroundRepeat='no-repeat' backgroundSize='cover' backgroundBlendMode='overlay' width='331px' height='102px'>
                <Center>
                    <Text color='#F9F8FD' fontFamily={StaticUtils.titleFontName}  lineHeight= "24px" fontSize='20px' fontWeight='700'>{text}</Text>
                </Center>
            </Box>
        </>
    )
}
export default WhyRentitySection;