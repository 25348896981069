import { Box, Button, HStack, Image, Stack, Text, VStack, useBreakpointValue, Center, SimpleGrid } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";

import Link from 'next/link'
import Slider from "react-slick";
import StaticUtils from "../../services/static-utils.service";
import { useRouter } from 'next/router';
import ImageWithTitle from "../Card/ImageWithTitle";

const HomeNewCollectionCarousel = (props: any) => {
  const router = useRouter();
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const [artsAndInteriorsData, setArtsAndInteriors] = useState<any>([]);
  const [artHeading, setArtHeading] = useState(props?.title);
  const [backGroundColor, setBackGroundColor] = useState(props?.bgColor);

  useEffect(() => {
    let refinedData: any = [];
    function refineArtsInteriorsData() {
      if (props?.data) {
        props?.data?.map((val: any, key: any) => {
          refinedData.push({
            "title": `${val?.title}`,
            "image": `${val?.image?.url}`,
            "imageAlt": `${val?.image?.alt}`,
            "imageHeight": `${val?.image?.dimensions?.width}`,
            "imageWidth": `${val?.image?.dimensions?.height}`,
            "sub_title": `${val?.sub_title}`,
            "url": `${val?.url?.url}`
          });

        });
        setArtsAndInteriors(refinedData);
      }
    }
    refineArtsInteriorsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.data]);


  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: false,
        },
      },
      {
        breakpoint: 1580,
        settings: {
          slidesToShow: 3.6,
          slidesToScroll: 3.6,
          infinite: false,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3.3,
          slidesToScroll: 3.3,
          infinite: false,
        },
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
        },
      },
      {
        breakpoint: 1220,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 2.4,
          infinite: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 2.4,
          infinite: false,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: false,
        },
      },
      {
        breakpoint: 837,
        settings: {
          infinite: false,
          slidesToShow: 3.5,
          slidesToScroll: 3.5,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 768,
        settings: {
          infinite: false,
          slidesToShow: 3.8,
          slidesToScroll: 3.8,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          infinite: false,
          slidesToShow: 2.5,
          slidesToScroll: 2.5,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 2.7,
          slidesToScroll: 2.7,
          initialSlide: 0,
          infinite: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.6,
          slidesToScroll: 2.6,
          infinite: false
        },
      },
      {
        breakpoint: 435,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2.5,
          initialSlide: 0,
          infinite: false,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 2.3,
          slidesToScroll: 2.3,
          initialSlide: 0,
          infinite: false,
        },
      },
      {
        breakpoint: 390,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 2.2,
          infinite: false
        },
      },
    ],
  };

  return (artsAndInteriorsData && artsAndInteriorsData.length ?
    <Box pl={isDesktop ? "24px" : "10px"} pr={isDesktop ? '24px' : '0px'}
      pt={{ base: '0', lg: "89px" }}
      pb={{ base: "36px", lg: "40px" }}
      backgroundColor={backGroundColor || 'white'}>
      <Text color={{ base: '#000000', lg: '#1F2D47' }} fontFamily={StaticUtils.titleFontName} fontWeight={{ base: "700", lg: "700" }} fontSize={{ base: "20", lg: "24px" }} mb={{ base: "10px", lg: "45px" }} ml={{ base: "5px", lg: "38px" }}>
        {artHeading}
      </Text>
      <Slider {...settings}>
        {
          artsAndInteriorsData.map((iVal: any, iKey: any) => {
            const url = (iVal.url != "undefined" && iVal.url != null) ? iVal.url : "";
            return (
              <SimpleGrid ml="2" key={iKey}>
                <ImageWithTitle
                  goToLink={url}
                  imgSrc={iVal.image}
                  productName={iVal.title}
                  price={iVal.sub_title}
                />
              </SimpleGrid>
            )
          })
        }
      </Slider>
      <Center>
        <Link href={'/search?search=;category='}>

          <Button variant='outline' fontFamily={StaticUtils.titleFontName} fontWeight={{ base: "700", lg: "700" }} mt={{ base: '15px', lg: '55px' }} w='215px' h='46px' bgColor='white'>Browse all products</Button>

        </Link>
      </Center>
    </Box >
    : <Box />
  );
}
export default HomeNewCollectionCarousel;