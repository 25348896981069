import { Box, Flex, HStack, Skeleton, VStack, useBreakpointValue } from "@chakra-ui/react";
import React, { useState } from "react";

import Image from 'next/image';
import Link from 'next/link'
import Router from "next/router";
import Slider from "react-slick";

const HeroBannerCarousel = (props: any) => {
  const [categories, setCategories] = useState([]);
  const bannerData = props?.heroCarousel?.props?.pageRes;
  let refinedHCData: any = [];
  const heroSaticBannerData = props?.heroStaticBanner?.props?.pageRes;
  const heroStaticData: any = heroSaticBannerData?.[0]?.primary;

  const [heroSaticBannerImg, setHeroSaticBannerImg] = useState(heroStaticData?.banner?.url);
  const [heroSaticBannerAlt, setHeroSaticBannerAlt] = useState(heroStaticData?.banner?.alt);
  const [heroSaticBannerTitle, setHeroSaticBannerTitle] = useState(heroStaticData?.title?.[0].text);
  const [heroSaticBannerLink, setHeroSaticBannerLink] = useState(heroStaticData?.link?.url);
  const [heroSaticBannerWidth, setHeroSaticBannerWidth] = useState(heroStaticData?.banner?.dimensions?.width);
  const [heroSaticBannerHeight, setHeroSaticBannerHeight] = useState(heroStaticData?.banner?.dimensions?.height);

  // fetch product data
  React.useMemo(() => {
    if (categories && categories.length) {
      return;
    }
    bannerData?.map((val: any, key: any) => {

      refinedHCData.push({
        "title": `${val?.primary?.title[0]?.text}`,
        "banner": `${val?.primary?.banner?.url}`,
        "bannerWidth": `${val?.primary?.banner?.dimensions?.width}`,
        "bannerHeight": `${val?.primary?.banner?.dimensions?.height}`,
        "mobile_banner": `${val?.primary?.mobile_banner?.url}`,
        "mobile_banner_width": `${val?.primary?.mobile_banner?.dimensions?.width}`,
        "mobile_banner_height": `${val?.primary?.mobile_banner?.dimensions?.height}`,
        "url": `${val?.primary?.link?.url}`,
        "tooltip": `${val?.primary?.tooltip[0]?.text}`
      });

      setCategories(refinedHCData);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, bannerData]);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    arrows: true,
    accessibility: true,
    dots: true,
    autoplaySpeed: 6000,
    adaptiveHeight: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 390,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: true,
        },
      },
    ],
  };

  const isDesktop = useBreakpointValue({ base: false, lg: true });

  return (
    <>
      {categories && categories.length && <>
        <Box display="flex" width="100%" columnGap={'16px'} className={isDesktop ? "hero-slide-wrapper" : "hero-slide-wrapper-mobile"} ml={isDesktop ? "64px" : "0"}>
          {isDesktop && heroSaticBannerImg &&
            <Link
              href={(heroSaticBannerLink != "undefined" && heroSaticBannerLink != null ? heroSaticBannerLink : "/") || '/'}
              aria-label="Why Rentity"
              style={{width: "40%"}}>

              <Box
                 width={isDesktop ? "100%" : "0"}
                mt={isDesktop ? '0' : '20px'}
                justifyContent="flex-start"
                minHeight={"438px"} maxHeight={"438px"}
                style={{position:"relative"}}
              >
                {heroSaticBannerImg &&
                <Image
                  fill
                  style={{ objectFit: "fill", minHeight: "438px", maxHeight: "438px", cursor: "pointer" ,width : "100%"}}
                  src={heroSaticBannerImg}
                  alt={heroSaticBannerAlt != "undefined" && heroSaticBannerAlt != null ? heroSaticBannerAlt : heroSaticBannerTitle}
                  title={heroSaticBannerAlt != "undefined" && heroSaticBannerAlt != null ? heroSaticBannerAlt : heroSaticBannerTitle}
                  draggable="false"
                />
                }  
              </Box>
                
            </Link>}
            <Box flex="1 1 auto" width={isDesktop ? "60%" : "100%"} paddingRight={isDesktop ? "64px" : "0"}>
              <Slider {...settings}>
                {categories && categories.map((cat: any, idx: number) => {
                  return (
                    (<Link
                      href={(cat.url != "undefined" && cat.url != null ? cat.url : "/") || '/'}
                      key={idx}
                      aria-label="Click to navigate to the page">

                      {isDesktop ? <Box mt={'0'} key={idx + '_'} width="100%" height="100%" className="hero-slide-container"
                        // onClick={() => handleNav(cat.url)} backup
                        style={{ margin: "0" }}
                      >
                        <Image
                          key={idx}
                          width={cat.bannerWidth < 974 ? 974 : cat.bannerWidth}
                          height={cat.bannerHeight}
                          src={cat.banner}
                          alt={cat.tooltip != "undefined" && cat.tooltip != null ? cat.tooltip : cat.title}
                          title={cat.tooltip != "undefined" && cat.tooltip != null ? cat.tooltip : cat.title}
                          draggable="false"
                          style={{ minHeight: "438px", maxHeight: "438px", objectFit : "fill", cursor: "pointer" }}
                        />
                      </Box>
                        :
                        <Flex mt={'20px'} key={idx + '_'} width="100%" maxHeight={"343px"} minHeight={"343px"} className="hero-slide-container"
                          position='relative'
                          alignContent='center'
                          justifyContent="center"
                          style={{ margin: "0" }}
                        >
                          <Image
                            key={idx}
                            width={cat.mobile_banner_width < 383 ? 383 : cat.mobile_banner_width}
                            height={cat.mobile_banner_height}
                            src={cat.mobile_banner}
                            alt={cat.tooltip != "undefined" && cat.tooltip != null ? cat.tooltip : cat.title}
                            title={cat.tooltip != "undefined" && cat.tooltip != null ? cat.tooltip : cat.title}
                            draggable="false"
                            style={{ maxHeight: "343px", objectFit: "fill", cursor: "pointer" }}
                          />
                        </Flex>
                      }

                    </Link>)
                  );
                })}
              </Slider>
            </Box>
        </Box>
      </>}
    </>
  );
};

export default HeroBannerCarousel;

function handleNav(slug: any): void {
  if (slug != "undefined")
    Router.push(slug);
}
