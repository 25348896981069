import React, { useState } from 'react';
import { HStack, Checkbox, Button, Flex, extendTheme, ChakraProvider, Text, Box, SimpleGrid } from '@chakra-ui/react';
import { useRouter } from 'next/router'
import { analytics } from '../../services/analytics.service';

function UserWelcomeContent(props: any) {
    const router = useRouter();
    const theme = extendTheme({
        components: {
            Checkbox: {
                baseStyle: {
                    control: {
                        borderRadius: "50%",
                        borderWidth: "2px",
                        _checked: {
                            borderColor: "#E47066",
                            color: "#E47066",
                            padding: "2"
                        },
                    },
                },
            },
        },
    });
    const [checkedCount, setCheckedCount] = useState(0);
    const [itemId, setItemId] = useState(0);

    // Function to update checked count
    const [selectedCheckboxIds, setSelectedCheckboxIds] = useState<number[]>([]); 0
    const [selectedCheckboxNames, setSelectedCheckboxNames] = useState<string[]>([]);

    const handleCheckboxChange = (id: number, names: any) => {
        const isSelected = selectedCheckboxIds.includes(id);
        const updatedSelectedIds = isSelected
            ? selectedCheckboxIds.filter(selectedId => selectedId !== id)
            : [...selectedCheckboxIds, id];
        setSelectedCheckboxIds(updatedSelectedIds);
        setItemId(id);
        setCheckedCount(updatedSelectedIds.length);
        if (names && names.length > 0) {
            const name = names[0]?.text
            const updatedSelectedNames = isSelected
                ? selectedCheckboxNames.filter(selectedName => selectedName !== name)
                : [...selectedCheckboxNames, name];
            setSelectedCheckboxNames(updatedSelectedNames);
        }
    };

    const sendAnalytics = () => {
        if (selectedCheckboxNames.length > 1) {
            selectedCheckboxNames.map((item: string) => {
                analytics.sendUserExperinceSelection(item)
            })
        }
        analytics.sendUserExperinceSelection("Selected")
        analytics.sendUserExperinceSelection(selectedCheckboxNames)
    }

    const handleNext = () => {
        analytics.sendUserExperinceSelection("Viewed")
        if (checkedCount > 0) {
            sessionStorage.setItem('hasVisited', 'true');
            // Google Analytics
            sendAnalytics()
            const containsNullOrZero = selectedCheckboxIds.some(id => id === null || id === 0);
            if (containsNullOrZero) {
                // If any checkbox ID is null or zero, it will go to URL
                router.push('search?search=;category=');
            } else if (selectedCheckboxIds.length > 0) {
                // If there are valid selected checkbox IDs
                const queryParams = selectedCheckboxIds.map(id => `${id}`).join(',');
                const url = `search?search=;category=;facets=${queryParams}`;
                router.push(url);
            } else {
                router.push('search?search=;category=');
            }
        }
    };

    const handleModalClose = (key: any) => {
        props.onClose(key)
    }

    return (
        <>
            <Flex direction="row" gap={3} >
                {props?.data?.slices?.map((slice: any, id: number) => (
                    <SimpleGrid
                        flex={1}
                        // align="flex-start"
                        backgroundColor="#FFFFFF"
                        py="4"
                        pr="4"
                        mb="2"
                        borderRadius="4px"
                        border="1px solid rgba(0, 0, 0, 0.1)"
                        key={"slice" + id}
                    >
                        <Flex
                            direction="column"
                            style={{
                                height: '290px',
                                overflowY: 'auto',
                                width: '100%',
                            }}
                        >
                            <Box
                                pb="3"
                                position="sticky"
                                top="0"
                                backgroundColor="#FFFFFF"
                                zIndex="1"
                            >
                                <Text fontSize="16px" fontWeight="600" opacity={0.7} color="#1F2D47" pl={4}>
                                    {slice?.primary?.title[0]?.text}
                                </Text>
                            </Box>
                            {slice?.items?.slice().sort((a: any, b: any) => a.display_order - b.display_order).map((item: any, id: number) => (
                                <Flex key={`${item.facetId}-${item.name}`}>
                                    <ChakraProvider theme={theme}>
                                        <Checkbox
                                            size="lg"
                                            mt="2"
                                            mb="2"
                                            mr="10px"
                                            pl={4}
                                            colorScheme='#E47066'
                                            onChange={() => handleCheckboxChange(item.facetId[0].text, item.name)}
                                        >
                                            <Flex ml="5px">
                                                {item.image.url ? (
                                                    <Text >
                                                        <img
                                                            src={item.image.url}
                                                            alt={item.image.alt}
                                                            width={item.image.dimensions.width}
                                                            height={item.image.dimensions.height}
                                                        // Increase image width by 2px
                                                        // style={{ width: `${parseInt(item.image.dimensions.width) + 8}px` }}
                                                        />
                                                    </Text>
                                                ) : (
                                                    item.name.map((name: any, textId: number) => (
                                                        <Text key={textId} fontSize={{base: "14px",md:"17px"}} fontWeight="500" ml='5px' color="#1F2D47">
                                                            {name.text}
                                                        </Text>
                                                    ))
                                                )}
                                            </Flex>

                                        </Checkbox>
                                    </ChakraProvider>
                                </Flex>
                            )
                            )}
                        </Flex>
                    </SimpleGrid>
                ))}

            </Flex>
            <HStack justifyContent="space-between" alignItems="center" mt="1" mb="1" height="48px">
                <Flex fontSize={{ base: "16px", lg: "18px" }} fontWeight="500" color="#027CEC" cursor="pointer" onClick={() => { props?.onClose("Skip") }}>
                    <Text>
                        Skip
                    </Text>
                </Flex>
                <Flex>
                    {checkedCount > 0 && (
                        <Button
                            backgroundColor="#1F2D47"
                            border="1px solid #1F2D47"
                            onClick={handleNext}
                            size="lg"
                            width="190px"
                            height="48px"
                            borderRadius="4px"
                        >
                            NEXT
                        </Button>
                    )}
                </Flex>
            </HStack>
        </>
    );
}
export default UserWelcomeContent;
