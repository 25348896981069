import { Box, Button, Flex, HStack, SimpleGrid, Stack, Text, VStack, useBreakpointValue } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";

import Image from 'next/image';
import Link from 'next/link'
import Slider from "react-slick";
import StaticUtils from "../../services/static-utils.service";
import { useRouter } from 'next/router';

const BrandCarousel = (props: any) => {
    const router = useRouter();
    const isDesktop = useBreakpointValue({ base: false, lg: true });
    const [browseByBrandsData, setBrowseByBrandsData] = useState<any>([]);

    useEffect(() => {
        let refinedData: any = [];
        function refineBrandsData() {
            if (props.data) {
                props.data.map((val: any, key: any) => {

                    refinedData.push({
                        "title": `${val?.primary?.title}`,
                        "description": `${val?.primary?.description[0]?.text}`,
                        "logo": `${val?.primary?.logoimage?.url}`,
                        "logoImageAlt": `${val?.primary?.logoimage?.alt}`,
                        "logoHeight": `${val?.primary?.logoimage?.dimensions?.width}`,
                        "logoWidth": `${val?.primary?.logoimage?.dimensions?.height}`,
                        "logoUrl": `${val?.primary?.logoimage?.url}`,
                        "bgGradientColor1": `${val?.primary?.bggradientcolor1}`,
                        "bgGradientColor2": `${val?.primary?.bggradientcolor2}`,
                        "bgImage": `${val?.primary?.bgimage?.url}`,
                        "bgImageAlt": `${val?.primary?.bgimage?.alt}`,
                        "bgImgHeight": `${val?.primary?.bgimage?.dimensions?.width}`,
                        "bgImgWidth": `${val?.primary?.bgimage?.dimensions?.height}`,
                        "cta": `${val?.primary?.cta?.url}`,
                        "thumbNail": `${val?.primary?.thumbnail?.url}`,
                        "thumbNailAlt": `${val?.primary?.thumbnail?.alt}`,
                        "tmbImgHeight": `${val?.primary?.thumbnail?.dimensions?.width}`,
                        "tmbImgWidth": `${val?.primary?.thumbnail?.dimensions?.height}`
                    });

                });
                setBrowseByBrandsData(refinedData);
            }
        }
        refineBrandsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data]);


    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 2.8,
        slidesToScroll: 2.8,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 2.8,
                    slidesToScroll: 2.8,
                    infinite: false,
                },
            },
            {
                breakpoint: 1650,
                settings: {
                    slidesToShow: 2.6,
                    slidesToScroll: 2.6,
                    infinite: false,
                },
            },
            {
                breakpoint: 1580,
                settings: {
                    slidesToShow: 2.4,
                    slidesToScroll: 2.4,
                    infinite: false,
                },
            },
            {
                breakpoint: 1441,
                settings: {
                    slidesToShow: 2.2,
                    slidesToScroll: 2.2,
                    infinite: false,
                },
            },
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 2.3,
                    slidesToScroll: 2.3,
                    infinite: false,
                },
            },
            {
                breakpoint: 1370,
                settings: {
                    slidesToShow: 2.1,
                    slidesToScroll: 2.1,
                    infinite: false,
                },
            },
            {
                breakpoint: 1286,
                settings: {
                    slidesToShow: 1.6,
                    slidesToScroll: 1.6,
                    infinite: false,
                },
            },
            {
                breakpoint: 1026,
                settings: {
                    slidesToShow: 1.6,
                    slidesToScroll: 1.6,
                    infinite: false,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1.5,
                    infinite: false,
                },
            },
            {
                breakpoint: 990,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                },
            },
            {
                breakpoint: 837,
                settings: {
                    infinite: false,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 0,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    infinite: false,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 0,
                },
            },
            {
                breakpoint: 670,
                settings: {
                    infinite: false,
                    slidesToShow: 2.4,
                    slidesToScroll: 2.4,
                    initialSlide: 0,
                },
            },
            {
                breakpoint: 580,
                settings: {
                    slidesToShow: 2.3,
                    slidesToScroll: 2.3,
                    initialSlide: 0,
                    infinite: false,
                },
            },

            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2.2,
                    slidesToScroll: 2.2,
                    infinite: false
                },
            },
            {
                breakpoint: 435,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 0,
                    infinite: false,
                },
            },
            {
                breakpoint: 420,
                settings: {
                    slidesToShow: 1.9,
                    slidesToScroll: 1.9,
                    initialSlide: 0,
                    infinite: false,
                },
            },
            {
                breakpoint: 390,
                settings: {
                    slidesToShow: 1.8,
                    slidesToScroll: 1.8,
                    infinite: false
                },
            },
            {
                breakpoint: 350,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1.5,
                    infinite: false
                },
            },
        ],
    };

    return (browseByBrandsData && browseByBrandsData.length ?
        <Box fontFamily={StaticUtils.titleFontName} pl={{ base: '10px', lg: '12px' }} py={{ base: '10px', lg: '49px' }}>
            <Text color={{ base: '#000000', lg: '#1F2D47' }} fontWeight={{ base: "700", lg: "700" }} fontSize={{ base: "20", lg: "24px" }} mb={{ base: "2", lg: "19" }} ml={{ base: "-8px", lg: "30px" }}>
                BROWSE BY BRANDS
            </Text>
            <Slider {...settings}>
                {
                    browseByBrandsData.map((iVal: any, iKey: any) => {
                        return (
                            isDesktop ?
                                <Box key={iKey} minW='532px' maxW='532px' ml={iKey == 0 ? { lg: '5px', base: '0px' } : '0px'} minH='275px' maxH='275px' borderRadius='6px' backgroundImage={`url(${iVal?.bgImage})`}
                                    backgroundRepeat='no-repeat' backgroundSize='cover' p='20px'>
                                    <>
                                        <HStack gap='52px' style={{ 'margin': '0px' }} alignItems='flex-start'>
                                            {iVal.thumbNail != 'undefined' ?
                                                <Image src={iVal?.thumbNail} alt={iVal?.thumbNailAlt} style={{ borderRadius: '10px' }} width={161} height={189} />
                                                : <Box w='161px' h='189px'></Box>
                                            }
                                            <VStack alignItems='flex-start' gap='25px' style={{ 'margin': '0px' }}>

                                                {iVal?.logoUrl != 'undefined' ?
                                                    <Image style={{ 'margin': '0px' }} alt={iVal?.logoImageAlt} src={iVal?.logoUrl} width={226} height={61} /> :
                                                    <Box w='226px' h='61px'></Box>
                                                }

                                                {iVal?.description != 'undefined' ?
                                                    <Text color='#000000' fontFamily={StaticUtils.titleFontName} style={{ 'margin': '0px' }} fontWeight='400' w='283px' h='72px' fontSize='16px'>{iVal?.description}</Text> :
                                                    <Box w='283px' h='72px'></Box>}

                                                {![iVal.cta, iVal.title].includes('undefined') ?
                                                    <Link aria-hidden="true" href={iVal.cta || '/'}>

                                                        <Button aria-hidden="true" fontFamily={StaticUtils.titleFontName} style={{ 'margin': '0px' }} bg='#FFFFFF' variant='outline' w='215px' h='46px'
                                                            onClick={() => router.push(iVal.cta)}>{`Browse ${iVal.title}`}</Button>

                                                    </Link>
                                                    :
                                                    <Box w='215px' h='46px'></Box>
                                                }
                                            </VStack>
                                        </HStack>
                                    </>
                                </Box>

                                :
                                
                                <>
                                    <SimpleGrid ml={2}>
                                        <Box
                                            key={iKey}
                                            borderRadius='6px'
                                            backgroundImage={`url(${iVal.bgImage})`}
                                            backgroundRepeat='no-repeat'
                                            backgroundSize='cover'
                                            p='8px'
                                            height="205px"
                                        // maxW='190px' maxH='202.15px'
                                        >
                                            <VStack gap='12px' style={{ 'margin': '0px' }}>
                                                <HStack gap='8px' style={{ 'margin': '0px' }} >

                                                    {iVal.thumbNail !== 'undefined' ? (
                                                        <Box w='57.5px' h='70px' minH='70px' maxH='70px'>
                                                            <Image src={iVal.thumbNail} alt={iVal.thumbNailAlt} width={57.5} height={67.5} />
                                                        </Box>
                                                    ) : (
                                                        <Box w='57.5px' h='67.5px' minH='67.5px' maxH='67.5px'></Box>
                                                    )}

                                                    {iVal?.logoUrl != 'undefined' ?
                                                        <Image
                                                            src={iVal.logoUrl}
                                                            alt={iVal.logoImageAlt}
                                                            style={{ 'margin': '0px' }}
                                                            width={101.51}
                                                            height={27.4}
                                                        />
                                                        : <Box w='101.51px' h='27.4px'></Box>
                                                    }
                                                </HStack>
                                            </VStack>

                                            <Flex style={{ 'margin': '0px' }} gap='19px' direction='column'>
                                                {iVal?.description != 'undefined' ?
                                                    <Text
                                                        color='#000000'
                                                        fontFamily={StaticUtils.titleFontName}
                                                        fontWeight='400'
                                                        lineHeight='14px'
                                                        fontSize='14px'
                                                        mt="2"
                                                        h="60px"
                                                    >
                                                        {iVal.description}
                                                    </Text>
                                                    : <Box w='167.75px' h='53.1px'></Box>
                                                }
                                                <Flex justifyContent="center" >
                                                    {![iVal.cta, iVal.title].includes('undefined') ?
                                                        <Button
                                                            aria-hidden="true"
                                                            fontSize={'14px'}
                                                            bg={'#FFF'}
                                                            fontFamily={StaticUtils.titleFontName}
                                                            variant='outline'
                                                            w='167px'
                                                            style={{ 'height': '32px' }}
                                                            onClick={() => router.push(iVal.cta)}
                                                        >
                                                            {`Browse ${iVal.title}`}
                                                        </Button>
                                                        :
                                                        <Box w='167px' h='32px'></Box>
                                                    }
                                                </Flex>
                                            </Flex>

                                        </Box>
                                    </SimpleGrid>
                                </>
                        )
                    })
                }
            </Slider>
        </Box >
        : <Box />
    )
}
export default BrandCarousel;   