import { Box, HStack, Stack, Text, VStack, useBreakpointValue } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";

import StaticUtils from "../../services/static-utils.service";
import moment from "moment"
import { useRouter } from 'next/router'
import Image from 'next/image';

type ListProps = {
    mainImage: string;
    numberImage: string;
    titleText: string;
    description: string;
    mainImageAlt?: any;
    numberImageAlt?: any;
    numberImageWidth?: any;
    numberImageHeight?: any;

}

const SubSection = (props: ListProps) => {
    const isDesktop = useBreakpointValue({ base: false, lg: true });

    const { mainImage, numberImage, titleText, description, mainImageAlt, numberImageAlt, numberImageWidth, numberImageHeight } = props
    const mainAlt = (mainImageAlt != "undefined" && mainImageAlt != null) ? mainImageAlt : titleText;
    const numbAlt = (numberImageAlt != "undefined" && numberImageAlt != null) ? numberImageAlt : titleText;
    return (
        <>
            <HStack alignItems="flex-start" spacing={0} mr={{ lg: '67px' }}>

                <Stack direction={{ lg: 'row', base: 'column' }} alignItems={{ base: 'center' }} spacing="0" pb={{ base: '0' }} h={{ lg: '322px' }}>

                    <Box mr={{ base: '0', lg: '10px' }} pb={{ base: '0' }}><Image src={mainImage} alt={mainAlt} width={isDesktop ? "335" : "252"} height={isDesktop ? "305" : "229"} /></Box>

                    <VStack alignItems='flex-start' color="white" spacing={3} mt="10px" w='260px'>
                        <Image src={numberImage} alt={numbAlt} width={`${numberImageWidth}`} height={`${numberImageHeight}`} />
                        <Text color='#E47066' fontSize={{ base: "20px", lg: "24px" }} fontFamily={StaticUtils.titleFontName} fontWeight="500" lineHeight={{ base: "26px", lg: "31.2 px" }}>{titleText}</Text>
                        <Text color='#1F2D47' w="90%" fontFamily={StaticUtils.titleFontName} fontSize="14px" lineHeight="22px" fontWeight="400" textAlign={isDesktop ? "left" : "justify"}>{description}</Text>
                    </VStack>

                </Stack>
            </HStack>
        </>
    )
}
export default SubSection;