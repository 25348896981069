import {
    Box,
    Button,
    Flex,
    Text,
    VStack,
    useBreakpointValue
} from "@chakra-ui/react";

import Image from 'next/image';
import React from "react";
import StaticUtils from "../../services/static-utils.service";

type pageProps = {
    pageTitle?: any;
    pageSynopsis?: any;
    pagePromoImgUrl?: any;
    pagePromoImgAltText?: any;
    pagePromoImgWidth?: any;
    pagePromoImgHeight?: any;
    pageDescription?: any;
    pageLink?: any;
};

const ContentPages = (props: pageProps) => {
    const isDesktop = useBreakpointValue({ base: false, lg: true });
    const { pageTitle, pageSynopsis, pagePromoImgUrl, pagePromoImgAltText, pagePromoImgWidth, pagePromoImgHeight, pageDescription, pageLink } = props;
    const altText = (pagePromoImgAltText != "undefined" && pagePromoImgAltText != null) ? pagePromoImgAltText : pageTitle;
    return (
        <Flex maxW='100vw' objectFit="contain" gap='10' pl={{ base: "15px", lg: "15%" }} pr={{ base: "15px", lg: "15%" }} pt={{ base: "0", lg: "69px" }} pb={{ base: "20px", lg: "66px" }} bg={{ base: "#FBF8F5", lg: "#FFFFFF" }}>
            {isDesktop && pagePromoImgUrl &&
                <Box width="100%"><Image
                    src={`${pagePromoImgUrl}`} alt={`${altText}`} width={`${pagePromoImgWidth}`} height={`${pagePromoImgHeight}`} />
                </Box>
            }
            <VStack alignItems='center' bg="#FFFFFF">
                {!isDesktop && pagePromoImgUrl && <VStack borderRadius="4px" display={"flex"}>
                    <Image src={`${pagePromoImgUrl}`} alt={`${altText}`} width={`${pagePromoImgWidth}`} height={`${pagePromoImgHeight}`} />
                </VStack>}
                <VStack display={"flex"} justifyContent={"flex-start"} alignSelf={!isDesktop ? "center" : "flex-start"}>
                    <Text fontFamily={StaticUtils.titleFontName} fontWeight={700} fontSize={{ base: "20px", lg: "24px" }} lineHeight="24px" letterSpacing={"0.11em"} pt={{ base: "20px", lg: "64px" }} pb={{ base: "20px", lg: "33px" }} color="#1F2D47" textTransform={"uppercase"} whiteSpace={"nowrap"}>{pageTitle}</Text>
                </VStack>
                <VStack pb={{ base: "20px", lg: "41px" }}>
                    <Text fontFamily={StaticUtils.titleFontName} fontWeight={400} lineHeight={{ base: '30px', lg: '36px' }} fontSize={{ base: "14px", lg: "16px" }} color="#000000" pl={{ base: "18px", lg: "0" }} pr={{ base: "18px", lg: "0" }} textAlign={isDesktop ? "left" : "justify"}>{pageSynopsis}</Text>
                </VStack>
                <VStack display={"flex"} justifyContent={"flex-start"} alignSelf={!isDesktop ? "center" : "flex-start"}>
                    {pageLink != undefined && <Button variant="outline" borderColor="#E47066" borderRadius="4px" bg="white" color="primary" w="211px" h="46px">
                        <Text fontFamily={StaticUtils.titleFontName} ><a href={pageLink || '/'}>Learn more</a></Text>
                    </Button>}
                </VStack>
            </VStack>
        </Flex>
    )
};

export default ContentPages;