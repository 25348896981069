import React, { useState } from "react";
import HappyCustomersCarousel from "../Carousel/HappyCustomersCarousel";

const CustomerReviews = (props: any) => {
    const { reviewData } = props;

    let refinedData: any = [];
    reviewData.map((val: any, key: any) => {        
            refinedData.push({
                "profilePic": `${val?.primary?.profile_pic?.url}`,
                "alt": `${val?.primary?.profile_pic?.alt}`,
                "width": `${val?.primary?.profile_pic?.dimensions?.width}`,
                "height": `${val?.primary?.profile_pic?.dimensions?.height}`,
                "name": `${val?.primary?.reviewer_name[0]?.text}`,
                "designation": `${val?.primary?.designation[0]?.text}`,
                "location": `${val?.primary?.location[0]?.text}`,
                "review": `${val?.primary?.description[0]?.text}`
            });        
    });

    return (
        <HappyCustomersCarousel reviews={refinedData} />
    )
}
export default CustomerReviews;